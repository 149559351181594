@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.change-password-modal {
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 10%;
  background-color: $white-color;
  border-radius: 25px;
  padding: 20px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  input, select, textarea {
    font-size: 16px;
    @include smallScreen {
      font-size: 16px !important;
    }
  }


  @include extraLargeScreen {
    margin-top: 5%;
    width: 40%;
  }

  @include largeScreen {
    margin-top: 10%;
    width: 60%;
  }

  @include mediumScreen {
    margin-top: 15%;
    width: 70%;
  }

  @include smallScreen {
    margin-top: 25%;
    width: 85%;
  }

  .cross-btn {
    align-self: flex-end;
    margin-right: 5px !important;
    width: 24px;
    height: 24px;
  }

  .header-text {
    font-size: 44px;
    font-weight: 700;
    margin-top: 5%;
    text-align: center;

    @include mediumScreen {
      margin-top: 4%;
      font-size: 36px;
    }

    @include smallScreen {
      margin-top: 3%;
      font-size: 24px;
    }
  }

  .fields-stack {
    width: 90%;
  }

  .next-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}
