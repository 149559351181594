@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.modal-success {
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 15%;
  background-color: $white-color;
  border-radius: 25px;
  padding: 50px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  @include largeScreen {
    width: 70%;
    margin-top: 10%;
  }

  @include smallScreen {
    width: 90%;
    margin-top: 40%;
  }

  .title {
    font-size: 32px;
    font-weight: 700;
    margin-top: 5%;
    color: $primary-green-color;
    text-align: center;
  }

  .description {
    width: 90%;
    letter-spacing: 1px;
    text-align: center;
  }

  .button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}
