@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.heading {
  font-size: 28px !important;
  margin-top: 3% !important;
  text-align: center;

  @include largeScreen {
    margin-top: 0% !important;
    font-size: 50px !important;
  }

  @include mediumScreen {
    font-size: 20px !important;
  }

  @include extraSmallScreen {
    font-size: 16px !important;
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  pointer-events: none;
}

.separated {
  justify-content: center;
}

.shake {
  top: -15px;

  @include mediumScreen {
    top: -25px;
  }

  @include extraSmallScreen {
    top: -15px;
  }
}

.title-words {
  gap: 40px;
  margin: 0 5%;
  width: 90% !important;
}

.bux {
  background-color: transparent;
  margin: 0 12px !important;
  @include mediumScreen() {
    margin: 0 4px !important;
  }
}

.box {
  padding-bottom: 12px !important;
  font-size: 40px !important;
  font-weight: 200 !important;
  color: black;
  letter-spacing: 1.6px;
  line-height: 200px !important;
  text-align: center;
  text-justify: center;
  border: 8px solid;
  border-radius: 24px;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
  min-width: 150px;
  max-height: 140px;
  font-family: 'M PLUS Rounded 1c' !important;
  font-weight: 400 !important;
  color: #162731 !important;
  font-size: 24px;
  cursor: grab;
  user-select: none; /* Prevent text selection */
  touch-action: none; /* Prevent default touch action */

  &:last-child {
    margin-right: 0;
  }

  @include largeScreen() {
    border: 7px solid;
    min-width: 150px;
    max-height: 180px;
    padding-bottom: 4px !important;
  }

  @include mediumScreen() {
    font-size: 20px !important;
    border: 4px solid;
    min-width: 70px !important;
    max-height: 80px !important;
    margin: 0 4px !important;
  }

  @include extraSmallScreen() {
    font-size: 16px !important;
    max-height: 70px !important;
    border: 3px solid;
    padding: 0;
  }
}

.gif-overlay {
  position: absolute;
  top: 10%;
  width: 90px;
  height: 90px;
  transition: transform 1s ease-in-out;

  @include largeScreen {
    width: 125px;
    height: 125px;
  }

  @include mediumScreen {
    top: 6%;
    width: 60px;
    height: 60px;
  }
}

.yellow {
  background-color: $yellow-card-active !important;
  border-color: $yellow-card-border !important;
}

.pink {
  background-color: $pink-card-active !important;
  border-color: $pink-card-border !important;
}

.green {
  background-color: $green-card-active !important;
  border-color: $green-card-border !important;
}

.white {
  background-color: $white-card-active !important;
  border-color: $white-card-border !important;
}

.over {
  background-color: $white-card-active !important;
  border-color: #808080 !important;
}

.disabled {
  position: relative;
  transform: scale(0.8);
  transition-duration: 0.5s;
}

.disabled::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $disabled-card;
  border: 8px solid $disabled-card;
  border-radius: 25px;
}

.ghost {
  background-color: #ccc; /* Ghost appearance */
  opacity: 0.6; /* Ghost opacity */
  border-radius: 24px;
}
