@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.home-box {
  .home-stack {
    margin-top: 60px;
  }
  .home-children {
    width: 100%;
    height: calc(100vh - 60px);
  }
}

.home-background {
  background-color: $light-gray-color;
  min-height: 100vh;
  overflow-y: auto;
}

@include smallScreen() {
  .home-box {
    .home-stack {
      margin-top: 50px;
    }

    .home-children {
      height: calc(100vh - 50px);
    }
  }
}
