@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.session-card {
  border-radius: 40px !important;
  width: 100%;
  background-color: $mustard-card !important;
  padding-block: 5%;
  border: 8px solid $mustard-border-color;
  position: relative;

  .lock-image {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
  }

  .heading {
    text-align: center;
    filter: drop-shadow(0px 1px 1px $secondary-black-color);
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  .racoon-image {
    height: 228px;
    width: 215px;
  }

  .session-action {
    display: flex;
    justify-content: center;
    .session-button {
      text-transform: none;
      max-width: 225px;
      color: black;
      text-wrap: nowrap;
    }
  }
}

@include smallScreen() {
  .session-card {
    border-radius: 25px !important;
    border: 5px solid $mustard-border-color;
    padding-bottom: 20px;
    max-height: 300px;

    .heading {
      font-size: 16px;
    }

    .subtitle {
      font-size: 12px;
    }

    .racoon-image {
      height: 111px;
      width: 104px;
    }

    .session-action {
      .session-button {
        border-radius: 8px;
        height: 32px;
        font-size: 12px;
        letter-spacing: 0.8px;
      }
    }
  }
}

@include extraLargeScreen() {
  .session-card {
    height: 375px;
    padding-block: 2%;
    .subtitle {
      font-size: 16px;
    }

    .racoon-image {
      height: 180px;
      width: 180px;
    }
  }
}
