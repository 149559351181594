@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.results-screen {
  padding: 2%;
  padding-bottom: 100px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .profile-photo-container {
    align-items: center;

    .streak-box {
      background-color: $white-color;
      justify-content: center;
      width: 60%;
      border: 4px solid $primary-green-color;
      border-radius: 20px;
      padding-block: 2px;
      padding-inline: 8px;
      position: relative;
      bottom: 20px;

      .star {
        height: 16px;
        width: 16px;
      }
    }

    .profile-avatar {
      height: 120px;
      width: 120px;
      border: 5px solid $profile-picture-border-color;
    }
  }

  .heading-stack {
    width: 60%;
    align-items: center;
    justify-content: space-between;
    min-height: 65px;

    .header-image {
      position: absolute;
      z-index: 0;
      height: 96px;
      width: 370px;
    }

    .username {
      z-index: 1;
    }

    .results-section {
      width: 80%;
      align-items: center;

      .results-image {
        height: 209px;
        width: 507px;
      }
    }
  }

  .results-parent-container {
    width: 90%;
    height: 100%;
    background: transparent;
    max-height: 252px;
    position: relative;
    margin-top: 14%;

    .image-container {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 16px;
      z-index: 1;
      position: absolute;
      top: -36%;
      background: url(../../Assets/Images/score-star.svg) no-repeat center /
        contain;

      @include smallScreen() {
        padding-bottom: 18px;
        height: 65%;
        top: -30%;
      }

      .image-text {
        z-index: 3;
      }
    }
  }

  .results-section {
    width: 90%;
    height: 80%;
    border-radius: 30px !important;
    background-color: $green-card !important;
    border: 5px solid $primary-green-color;
    padding-top: 32px;
    padding-bottom: 16px;
    margin: auto;

    .result-stats {
      width: 90%;
      height: 80%;
      margin: auto;
      align-items: center;
      justify-content: space-between;

      .level-container {
        width: 120px;
        height: 120px;
        background: url(../../Assets/Images/score-star.svg) no-repeat center /
          contain;
      }

      .divider {
        height: 50px !important;
        background-color: $primary-green-color;
        opacity: 30%;
      }
    }
  }

  .list {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    align-items: center;
  }
}

@include smallScreen() {
  .results-screen {
    .profile-photo-container {
      .streak-box {
        border: 3px solid $primary-green-color;
        bottom: 24px;

        .star {
          height: 12px;
          width: 12px;
        }
      }

      .profile-avatar {
        height: 86px;
        width: 86px;
        border: 3px solid $profile-picture-border-color;
      }
    }

    .heading-stack {
      .header-image {
        height: 65px;
        width: 235px;
      }

      .results-section {
        .results-image {
          height: 125px !important;
          width: 300px !important;
        }
      }
    }
  }
}
