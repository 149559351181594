@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.settings-container {
  width: 100%;

  .general-settings-stack {
    width: 100%;

    .section-content {
      width: 100%;

      .first-stack {
        width: 100%;
        background-color: $white-color;
        border-radius: 20px;
        justify-content: center;
        border: 5px solid $box-border-light-gray-color;
        // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);

        .content {
          width: 100%;
          justify-content: space-between;
          align-items: center;
          height: 80px;

          .section-button {
            margin-left: 5%;
          }
        }
      }

      .second-stack {
        width: 100%;
        background-color: $white-color;
        border-radius: 20px;
        justify-content: space-evenly;
        height: 150px;
        width: 100%;
        border: 5px solid $box-border-light-gray-color;
        // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);

        .content {
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .section-button {
            color: $red-color;
            margin-left: 5%;
          }
        }

        .text {
          width: 75%;
          margin-left: 5%;
        }
      }
    }
  }

  .privacy-policy-stack {
    width: 100%;

    .box {
      width: 100%;
      background-color: $white-color;
      border-radius: 20px;
      justify-content: space-around;
      height: 70px;
      width: 100%;
      border: 5px solid $box-border-light-gray-color;
      // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);

      .content {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .section-button {
          margin-left: 5%;
          letter-spacing: 0.68px;
        }
      }
    }
  }

  .subscription-stack {
    width: 100%;
    border: 5px solid $box-border-light-gray-color;
    // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .box {
      width: 100%;
      background-color: $white-color;
      border-radius: 20px;
      justify-content: space-around;
      height: 70px;
      cursor: pointer;

      .content {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .section-button {
          margin-left: 5%;
          letter-spacing: 1.68px;
        }

        .right-arrow {
          color: $primary-green-color;
          margin-right: 20px;
        }
      }
    }
  }
}

@include mediumScreen() {
  .settings-container {
    .general-settings-stack {
      .section-content {
        .second-stack {
          height: 120px;
        }
      }
    }

    .privacy-policy-stack {
      .box {
        .content {
          .section-button {
            margin-left: 5%;
          }
        }
      }
    }
  }
}

@include extraLargeScreen {
  .settings-container {
    .privacy-policy-stack {
      .box {
        .content {
          .section-button {
            margin-left: 2.5%;
          }
        }
      }
    }
  }
}
