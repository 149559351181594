@import '../../Styles/mediaQueries';
.container {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .loader {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.notification{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px 0px 0px 20px;
}
.content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 100px !important;
}

.title {
  font-family: 'Lexend', sans-serif !important;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.subtitle {
  font-family: 'Lexend', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  text-align: center !important;
  margin-top: 40px !important;
}

.textContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.buttonText {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: black !important;
}

.buttonTextSmall {
  font-size: 14px !important;
  font-weight: normal !important;
  color: gray !important;
}

.buttonContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.button {
  background-color: #eef8ed !important;
  border: 2px solid #6dc25e !important;
  color: #6dc25e !important;
  width: 477.9px !important;
  height: 139.68px !important;
  border-radius: 13.85px !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  padding: 0 16px !important;
  margin: 10px 0 !important;
  box-shadow: none !important;
  text-transform: none !important;
  position: relative !important;

  &:hover {
    background-color: darken(#eef8ed, 5%) !important;
    border: 2px solid #6dc25e !important;
  }

  .iconContainer {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }

  .avatar {
    height: 80px !important;
    width: 80px !important;
  }

  .buttonText {
    flex-grow: 1 !important;
    text-align: center !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .arrow {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    position: absolute !important;
    right: 16px !important;
  }
}

@include smallScreen() {
  .button {
    width: 352px !important;
    height: auto !important;
    padding: 20px !important;
  }

  .content {
    padding: 30px !important;
  }

  .textContainer {
    margin-left: 18px !important;
  }

  .buttonText {
    flex-grow: 1 !important;
    text-align: center !important;
    color: var(--primary-text, #162731) !important;
    font-family: Lexend !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: 1.473px !important;
  }
  
  .buttonTextSmall {
    text-align: left;
    color: var(--secondary-text, #737D83) !important;
    font-family: Lexend !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 1.473px !important;
  }

  .title {
    color: var(--primary-text, #162731) !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Lexend !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 123.5% !important; /* 19.76px */
    letter-spacing: 0.25px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .subtitle {
    color: rgba(28, 37, 42, 0.80) !important;
    text-align: center !important;
    font-family: Lexend !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 25px !important; /* 253.988% */
    letter-spacing: 1.975px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .avatar {
    width: 43.289px !important;
    height: 33.454px !important;
    flex-shrink: 0 !important;
  }
}
