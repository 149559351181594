@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

#appbar {
  background-color: $white-color;
  position: fixed;
  width: 100%;
  justify-content: center;
  height: 60px;

  .appbar-container {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;

    .logo-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .logo-img {
        margin-right: 5%;
      }
    }

    .pdf-icon {
      margin-right: 10px;
      color: $primary-green-color;

      .pdf-link {
        text-decoration: none;
        color: inherit;
        outline: none;
        border: none;
        text-align: center;
      }
    }

    .settings-btn {
      margin-block: 3%;
      border: none;
      border-radius: 0px;
      background-color: $box-border-light-gray-color-2;

      .avatar {
        width: 36px;
        height: 36px;
      }
    }

    .profile-stack {
      margin-left: 10px;
      text-transform: none;
      text-align: start;
    }
  }
}

.popover {
  border-radius: 20px;
  padding: 0.5rem 0;
  .list-button {
    padding-left: 20px;
    padding-right: 50px;
    border-radius: 20px;
  }
}

@include smallScreen() {
  #appbar {
    height: 50px;

    .appbar-container {
      .logo-box {
        .logo-img {
          width: 30px;
          height: 30px;
        }
      }

      .pdf-icon {
        margin-right: 0px;
      }

      .settings-btn {
        .avatar {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
