@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.forgot-password-confirmation-box {
  align-items: center;
  justify-content: center;

  @include smallScreen {
    height: calc(100vh - 50px);
    justify-content: start;
  }

  .heading-box {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 70%;
    height: 100px;
    position: relative;
    margin-top: 30%;

    @include mediumScreen {
      margin-top: 40%;
      width: 100%;
      height: 50px;
    }

    @include smallScreen {
      width: 100%;
      height: 30px;
    }

    @include extraSmallScreen {
      width: 100%;
      height: 20px;
    }

    .heading-image {
      width: 500px;
      height: 250px;
      object-fit: cover;
      position: absolute;
      top: -160px;
      z-index: 11;

      @include mediumScreen {
        width: 400px;
        height: 200px;
        top: -110px;
      }

      @include smallScreen {
        width: 300px;
        height: 150px;
        top: -90px;
      }

      @include extraSmallScreen {
        width: 200px;
        height: 100px;
        top: -55px;
      }
    }

    .header-text {
      z-index: 11;
      font-size: 40px;

      @include smallScreen {
        font-size: 24px;
      }

      @include extraSmallScreen {
        font-size: 18px;
      }
    }
  }

  .header-text {
    margin-top: 5%;

    @include extraLargeScreen {
      margin-top: 20%;
    }
  }

  .sub-text {
    color: $primary-black-color;
    text-align: center;
    text-wrap: wrap;
    letter-spacing: 1.98px;
    line-height: 35.6px !important;
    width: 90%;
  }

  .box-stack {
    align-items: center;

    .continue-button {
      padding: 12px !important;
      text-transform: none !important;
      border-radius: 12px !important;
      height: 60px;

      @include smallScreen {
        height: 45px;
      }
    }

    .resend-link {
      color: $link-color;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 35.558px;
      letter-spacing: 1.975px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
