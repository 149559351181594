@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.list-container-stack {
  width: 90%;
  height: 100%;

  .divider {
    justify-content: center;

    .text {
      width: 10%;

      @include smallScreen {
        width: 50%;
      }
    }
    .horizontal-line {
      width: 75%;
      height: 2px;
      background-color: #828282;
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 2%;

      @include smallScreen {
        width: 60%;
      }
    }
  }

  .scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
}
