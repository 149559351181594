/* src/App.css */

.app {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .box {
    width: 100px;
    height: 100px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #61dafb;
    border: 2px solid #000;
    font-size: 24px;
    cursor: grab;
    user-select: none; /* Prevent text selection */
    touch-action: none; /* Prevent default touch action */
  }
  
  .ghost {
    background-color: #ccc; /* Ghost appearance */
    opacity: 0.6; /* Ghost opacity */
  }
  
  .success {
    margin-top: 20px;
    font-size: 18px;
    color: green;
  }
  