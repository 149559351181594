@import '../../Styles/mediaQueries';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px 0px;
  height: max-content;
}
.content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 20px !important;

  .title {
    font-family: 'Lexend', sans-serif !important;
    font-size: 48px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    letter-spacing: 0px !important;
    text-align: center !important;
  }

  .subtitle {
    font-family: 'Lexend', sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    margin-top: 15px !important;
    text-align: center !important;
    text-align: center !important;
  }

  .form {
    margin: 10px 0;
  }
}

.couponBox {
  display: flex !important;
  width: 105% !important;
  margin-left: 5px !important;
  justify-content: center !important;
  padding: 0 16px !important;
  box-sizing: border-box !important;
}

.couponInput {
  flex-grow: 1 !important;
  margin-right: -40px !important;
}

.applyButton {
  white-space: nowrap !important;
  width: 30% !important;
  z-index: 1 !important;
  margin-left: -37px !important;
}

.cardSelected {
  transform: scale(1.03) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  border: 5px solid white !important;
}

.error {
  color: red;
}

.cardContainer {
  display: flex !important;
  gap: 16px !important;
  margin-bottom: 24px !important;
  margin-top: 50px !important;
}

.card {
  height: 350px;
  top: 422.82px;
  left: 123.47px;
  border-radius: 40px !important;
  width: 250px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.cardTitle {
  margin-top: 70px !important;
}

.price {
  font-size: 50px !important; // Adjust as per your design
  font-weight: 600 !important;
  margin: 20px 0px 0px 0px !important;
}

.period {
  margin-bottom: 16px !important;
}

.proceedButton {
  margin-top: 10px !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  width: 100% !important;
  outline: none !important;
  cursor: pointer !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s !important;
}

.notification {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px 0px 0px 20px;
}

.proceedButton:hover {
  background-color: #59b948 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

.proceedButton:active {
  background-color: #59b948 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.proceedButton.disabled {
  background-color: #e0e0e0 !important;
  color: #a0a0a0 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}

.text {
  text-align: center;
  color: #737d83;
}

.trialText {
  margin-top: 10px;
  text-align: center;
  color: #737d83;
}

.disclaimer {
  margin-top: 10px !important;
  text-align: center;
  color: #737d83;
}

@include smallScreen() {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: max-content;
  }

  .cardTitle {
    color: rgba(28, 37, 42, 0.8) !important;

    text-align: center !important;
    font-family: Lexend !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 25.321px !important; /* 180.868% */
    letter-spacing: 1.721px !important;
    margin-top: 20px !important;
  }

  .price {
    color: #162731 !important;
    font-family: Lexend !important;
    font-size: 34px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-top: 10px !important;
  }

  .card {
    width: 178.781px;
    height: 242.73px;
    top: 422.82px;
    left: 123.47px;
    border-radius: 40px !important;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  .cardContainer {
    display: flex !important;
    gap: 6.5px !important;
    margin-bottom: 24px !important;
    margin-top: 10px !important;
  }

  .content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;

    .title {
      color: var(--primary-text, #162731) !important;
      font-feature-settings: 'clig' off, 'liga' off !important;
      font-family: Lexend !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 123.5% !important; /* 19.76px */
      letter-spacing: 0.25px !important;
    }
    .subtitle {
      color: rgba(28, 37, 42, 0.8);
      text-align: center;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 35.558px; /* 253.988% */
      letter-spacing: 1.975px;
    }
  }

  .proceedButton {
    display: flex;
    align-items: center;
    gap: 9.805px;
    flex-shrink: 0;
    margin-top: 20px !important;
    background-color: white !important;
    color: black !important;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    padding: 16px 40.22px !important;
    justify-content: center;
    font-size: 16px !important;
    font-weight: bold !important;
    width: 345px !important;
    height: 48px;
    outline: none !important;
    cursor: pointer !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    transition: all 0.3s !important;
  }

  .trialText {
    margin-top: 10px;
    text-align: center;
    font-size: 2px;
    color: #737d83;
  }
}
