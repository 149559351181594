@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.student-progress-container {
  align-items: center;
  padding-bottom: 50px;

  @include largeScreen {
    padding-bottom: 100px;
  }

  @include smallScreen {
    padding-bottom: 75px;
  }

  .student-progress-content {
    margin-top: 2%;
    width: 90%;
    align-items: center;
    justify-content: center;

    .heading {
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      .back-btn {
        width: 32px;
        height: 32px;

        .back-img {
          width: 32px;
          height: 32px;
        }
      }

      .page-name {
        font-weight: 700;
        color: $primary-black-color;
      }
    }

    .content-stack {
      width: 100%;
      .content-row {
        width: 100%;
      }
    }
  }
}
