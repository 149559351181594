@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.modal-box {
  width: 50%;
  height: 75%;
  margin: 5% auto;
  align-items: center;
  justify-content: center;
  background-image: url('../../Assets/Images/TutorialFlow/popup.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @include smallScreen {
    width: 90%;
    height: 55%;
    margin: 20% auto;
    border-radius: 20px;
  }

  @include extraLargeScreen {
    width: 35%;
    height: 65%;
    margin: 5% auto;
  }

  .modal-heading {
    margin-top: 30%;
    letter-spacing: 0px;
  }

  .modal-text {
    letter-spacing: 2.28px;
    line-height: 33.592px;
    text-align: center;
    width: 70%;
  }

  .modal-2-text {
    letter-spacing: 2.28px;
    line-height: 33.592px;
    width: 85%;
    text-align: center;

    @include extraLargeScreen {
      width: 80%;
    }
  }

  .modal-btn {
    background-color: $white-color;
    color: $primary-black-color;
    letter-spacing: 1.51px;
    padding: 15px 50px;
    font-weight: 700;
  }

  .gif-overlay {
    position: fixed;
    top: 51%;
    left: 50%;
    width: 100px;
    height: 100px;
    pointer-events: none;

    @include extraLargeScreen {
      top: 57%;

      @include extraLargeScreenByHeight {
        top: 56%;
      }
    }

    @include smallScreen {
      top: 48%;
    }

    @include extraSmallScreen {
      top: 52%;
    }
  }

  .gif-overlay-finish {
    position: fixed;
    top: 52%;
    left: 50%;
    width: 100px;
    height: 100px;
    pointer-events: none;

    @include extraLargeScreen {
      top: 59%;
    }

    @include smallScreen {
      top: 52%;
    }

    @include extraSmallScreen {
      top: 57%;
    }
  }
}

.profiles-modal-box {
  width: 50%;
  height: 75%;
  margin: 5% auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 51px;
  background: #f2f2f2;
  padding-bottom: 10px;
  overflow-y: auto;

  @include largeScreen {
    width: 50%;
  }

  @include smallScreen {
    width: 90%;
    height: 80%;
    margin: 20% auto;
    border-radius: 20px;
    max-height: 700px;
    overflow-y: auto;
  }

  @include extraLargeScreen {
    width: 50%;
    height: auto;
    max-height: 700px;
    overflow-y: auto;
    margin: 5% auto;

    &::-webkit-scrollbar {
      width: 0px; // Making scrollbar invisible
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; // Light background for the scrollbar track
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f1f1f1; // Darker color for the scrollbar thumb
      border-radius: 0px; // Rounded corners for the scrollbar thumb
      border: 2px solid #f1f1f1; // Matching scrollbar track color
    }

    // for Firefox
    scrollbar-width: thin;
    scrollbar-color: #f1f1f1;

    // scrollbar for IE, Edge
    & {
      -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
    }
  }

  .heading {
    justify-content: space-between;
    padding: 0px 20px;
    padding-top: 25px;
    align-items: center;

    .closeButton {
      border-radius: 0px !important;
    }

    .cross {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 0px !important;
    }
  }

  .avatar {
    width: 93.258px;
    height: 99.429px;
    flex-shrink: 0;
  }

  .listItemText {
    color: var(--primary-blue, #162731) !important;
    font-family: Lexend !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-top: 20px !important;
  }

  .levelText {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 30px;
    background: rgba(76, 175, 80, 0.31);
    width: fit-content;

    .listItemLevel {
      font-weight: bold !important;
    }
  }

  .listItemButton {
    border-radius: 16px;
    border: 4px solid #dfebde;
    background: #fff;
  }
  .listItemButtonSelected {
    border-radius: 16px;
    border: 4px solid #54b948;
    background: #fff;
  }
}

.home-container {
  width: 100% !important;
  margin: 0px !important;

  .beta-version-text {
    position: absolute;
    right: 20px;
    color: $maroon-color;
    font-size: 20px;

    @include smallScreen {
      font-size: 16px;
      right: 15px;
    }
  }

  .tutorial-button {
    position: fixed;
    bottom: 30px;
    right: 20px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: $white-color;
    border: 2px solid $primary-green-color;
    text-align: center;
    z-index: 11;

    &:hover {
      background-color: $primary-green-color;
      color: $white-color;
    }

    @include largeScreen {
      bottom: 80px;
      right: 30px;
    }

    @include smallScreen {
      border-radius: 10px;
      padding: 5px 10px;
      bottom: 80px;
      right: 20px;
    }
  }

  .greeting-text {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }

  .dropdown-icon {
    background-color: $light-gray-color;
    width: 24px;
    height: 24px;
  }

  .profile-box {
    width: 60%;
    padding-block: 10px;
    padding-inline: 20px;
    border: 3px solid $box-border-light-gray-color;
    border-radius: 20px;

    .profile-image {
      height: 15%;
      width: 15%;
    }

    .profile-stack {
      width: 100%;
      margin-left: 20px;

      .profile-level {
        font-weight: 500;
        letter-spacing: 0px;
        margin-bottom: 10px;
      }
    }
  }

  .rewards-box {
    cursor: pointer;
    width: 25%;
    padding-block: 10px;
    padding-inline: 20px;
    border: 3px solid $box-border-light-gray-color;
    border-radius: 20px;
    position: relative;

    .reward-sticker {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 120px;
      width: 120px;
    }

    .rewards-stack {
      width: 100%;
      margin-top: 100px;

      .rewards-text {
        font-weight: 800;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        letter-spacing: 0px;
      }
      .arrow {
        height: 30px;
        width: 25px;
        margin-top: 2px;
        margin-left: 10px;
        color: #59b948;
      }
    }
  }

  .session-box {
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 10px;
    text-align: start;
  }
}

@include smallScreen() {
  .home-container {
    .greeting-text {
      font-size: 18px;
    }

    .session-box {
      margin-bottom: 20px;
    }
  }
}
