@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.signup-container {
  height: calc(100vh - 60px);
  padding: 0px !important;
  background-color: $box-border-light-gray-color-2;

  @include smallScreen() {
    height: calc(100vh - 50px);
  }

  .base-stack {
    justify-content: center;
    align-items: flex-end;

    .background-img {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .signup-box {
      background-color: $white-color;
      max-width: 734px;
      z-index: 111;
      width: 33%;
      margin-right: 4%;
      border-radius: 20px;
      margin-block: 7%;
      padding-block: 5%;

      @include extraLargeScreen {
        margin-block: 2%;
        padding-block: 2%;
      }
    }
  }
}
