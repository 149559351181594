@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.separated {
  justify-content: center;
  margin: 0 5%;
  width: 90%;

  .sound-box-letters {
    padding-bottom: 12px !important;
    font-size: 137px !important;
    font-weight: 200 !important;
    color: black;
    letter-spacing: 1.6px;
    line-height: 200px !important;
    text-align: center;
    text-justify: center;
    border: 8px solid;
    border-radius: 24px;
    margin: 0 12px !important;
    padding: 0;

    justify-content: center;
    align-items: center;
    display: flex;

    //TODO: Fix the font-family and min-max width/height.
    //TODO: The font family must be applied through typography.
    //TODO: remove !important tags
    min-width: 180px;
    max-height: 210px;
    font-family: 'M PLUS Rounded 1c' !important;
    font-weight: 400 !important;
    color: #162731 !important;

    &:last-child {
      margin-right: 0;
    }

    @include largeScreen() {
      font-size: 100px !important;
      line-height: 170px !important;
      border: 7px solid;
      min-width: 150px;
      max-height: 180px;
      padding-bottom: 4px !important;
    }

    @include mediumScreen() {
      font-size: 60px !important;
      line-height: 150px !important;
      border: 6px solid;
      min-width: 80px;
      max-height: 120px;
      margin: 0 4px !important;
    }

    @include extraSmallScreen() {
      font-size: 36px !important;
      line-height: 110px !important;
      border: 3px solid;
      min-width: 60px;
      padding: 0;
      margin: 0 4px !important;
    }
  }
}

.shake {
  animation-name: shake;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: rotate(-9deg);
  }

  20% {
    transform: rotate(9deg);
  }

  40% {
    transform: rotate(-9deg);
  }

  60% {
    transform: rotate(9deg);
  }

  80% {
    transform: rotate(-9deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.yellow {
  background-color: $yellow-card-active !important;
  border-color: $yellow-card-border !important;
}

.pink {
  background-color: $pink-card-active !important;
  border-color: $pink-card-border !important;
}

.green {
  background-color: $green-card-active !important;
  border-color: $green-card-border !important;
}

.white {
  background-color: $white-card-active !important;
  border-color: $white-card-border !important;
}

.disabled {
  position: relative;
  transform: scale(0.8);
  transition-duration: 0.5s;
}

.disabled::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $disabled-card;
  border: 8px solid $disabled-card;
  border-radius: 25px;
}
