@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.login-box {
  input, select, textarea {
    font-size: 16px;
    @include smallScreen {
      font-size: 16px !important;
    }
  }
  .header-text {
    font-size: 48px;
    margin-top: 10%;

    @include smallScreen {
      font-size: 24px !important;
      letter-spacing: 0.25px;
    }

    @include extraLargeScreen {
      font-size: 32px !important;
      margin: auto;
    }
  }

  .login-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }

  .signup-text {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 35.558px !important;
    letter-spacing: 1.975px !important;
    cursor: pointer !important;
    align-self: flex-end !important;
  }
}
