.modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;

  .loading-modal {
    width: 100vw;
    height: 100%;
    z-index: 1000;
    margin: auto;
  }

  .words {
    background-color: white; 
  }
  
  .practice_one {
    background-color: #ffffec;
  }
  
  .challenge-now {
    background-color: #FEEDFA;
  }

  .practice-again {
    background-color: #ffffec;
  }

  .practice_two {
    background-color: #ffffec;
  }

  .practice_three {
    background-color: #ffffec;
  }

  .cross {
    position: absolute;
    top: 1%;
    right: 1%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

