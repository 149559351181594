@use '../../Styles' as *;

.login-container {
  height: calc(100vh - 60px);
  padding: 0px !important;
  
  .base-stack {
    justify-content: center;
    align-items: flex-end;

    .background-img {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .login-box {
      background-color: $white-color;
      max-width: 734px;
      z-index: 111;
      width: 33%;
      margin-right: 4%;
      border-radius: 20px;
      margin-block: 5%;
      padding-block: 2%;
    }
  }
}
