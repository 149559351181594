@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.session-finished-container {
  align-items: center;
  justify-content: center;
  padding: 25px;

  @include largeScreen {
    padding: 30px;
    padding-block-end: 100px;
  }

  @include smallScreen {
    padding: 20px;
    padding-block-end: 100px;
  }

  .result-stack {
    height: 100%;
    width: 80%;
    align-items: center;
    justify-content: space-evenly;

    @include mediumScreen {
      width: 90%;
    }

    @include smallScreen {
      width: 100%;
    }

    @include extraLargeScreen {
      width: 70%;
    }

    .heading {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 16px;

      @include smallScreen {
        font-size: 2rem;
      }
    }

    .score-stack {
      width: 80%;
      max-width: 650px;
      height: 200px;
      align-items: center;
      justify-content: center;
      border: 12px solid $dark-green-border-color;
      border-radius: 40px;
      background-color: $primary-green-color;
      position: relative;

      @include mediumScreen {
        margin-top: 25px;
      }

      @include smallScreen {
        width: 100%;
        height: 180px;
      }

      .score-text {
        color: $white-color;
        font-weight: 600;

        .correct {
          font-size: 120px;

          @include smallScreen {
            font-size: 80px;
          }
        }
      }

      .star-1 {
        position: absolute;
        top: 20px;
        left: 10px;
        filter: drop-shadow(0px 3.698px 3.698px $black-border-color);

        @include largeScreen {
          width: 90px;
          height: 90px;
        }

        @include smallScreen {
          width: 60px;
          height: 60px;
        }
      }
      .star-2 {
        position: absolute;
        top: -50px;
        right: 30%;
        filter: drop-shadow(0px 3.698px 3.698px $black-border-color);

        @include largeScreen {
          width: 90px;
          height: 90px;
          top: -40px;
          right: 25%;
        }

        @include smallScreen {
          width: 60px;
          height: 60px;
          top: -30px;
          right: 20%;
        }
      }
      .star-3 {
        position: absolute;
        bottom: 10px;
        right: 20px;
        filter: drop-shadow(0px 3.698px 3.698px $black-border-color);

        @include largeScreen {
          width: 90px;
          height: 90px;
        }

        @include smallScreen {
          width: 60px;
          height: 60px;
        }
      }
    }

    .chest-stack {
      width: 90%;
      align-items: center;
      justify-content: center;

      @include smallScreen {
        width: 90%;
      }

      .heading {
        font-size: 32px;
        text-align: center;
        width: fit-content;

        @include smallScreen {
          font-size: 20px;
        }
      }

      .sub-text {
        color: $label-gray-color;
      }

      .chest-btn {
        position: relative;
        display: flex;
        align-items: center;

        .chest-img {
          @include smallScreen {
            width: 150px;
            height: 150px;
          }
        }

        .gif-overlay {
          width: 90px;
          height: 90px;
          position: absolute;
          left: 60%;
          top: 50%;
        }
      }
    }

    .results-btn {
      text-transform: none;
      height: 60px;
      // width: 80%;
    }

    .back-btn {
      text-transform: none;
      height: 60px;
      padding: 12px !important;
      text-transform: none !important;
      border-radius: 12px !important;
      background-color: $white-color !important;
      color: $primary-black-color !important;
      border: 3px solid $primary-black-color !important;
    }
  }
}
