//COLORS
$primary-green-color: #54b948; //the green color used in the Get Started and Login button on splash screen and login page
$box-border-light-gray-color: rgba(
  237,
  237,
  237,
  1
); //the border of containers nav container and profile-level container on home page
$box-border-light-gray-color-2: #f2f2f2;
$box-border-light-gray-color-3: #e0e0e0;

//BORDER COLORS
$primary-green-color-opacity: rgba(84, 185, 72, 0.12); //54b948-12%
$profile-picture-border-color: rgba(202, 207, 219, 0.29);
$black-border-color: rgba(0, 0, 0, 0.1); //000-10%
$light-green-border-color: #dfebde;
$medium-green-border-color: #bfddc0;
$dark-green-border-color: #a1d7a2;
$mustard-border-color: #dddb86;
$red-border-color: #e2c4be;

//BACKGROUND COLORS
$white-color: #ffffff;
$white-color-less-opacity: rgba(255, 255, 255, 0.18); //ffffff-18%
$black-color: #000000;
$light-gray-color: #eeeeee;

//TEXT COLORS
$primary-black-color: #1c252a;
$secondary-black-color: rgba(22, 39, 49, 0.7);
$tertiary-black-color: rgba(22, 39, 49, 1); //#162731
$gray-color: #a2a9ad;
$dark-gray-color: #828282;
$label-gray-color: #737d83;
$red-color: #ff4d4d;
$maroon-color: #c73535;

//SESSION CARD COLORS
$salmon-card: rgba(250, 146, 113, 1);
$green-card: rgba(76, 175, 80, 0.23);
$mustard-card: #f8f6b2;
$red-card: #f6e0db;
$mustard-card-button: #a39f09;
$red-card-button: #d37f6e;
$blue-card: #dcf2f6;
$blue-card-border: #86c0dc;

//FLASH CARD COLORS
$yellow-card-active: #f8f6b2;
$yellow-card-border: #dddb86;

$green-card-active: #edfceb;
$green-card-border: #a1d7a2;

$pink-card-active: #f6e0db;
$pink-card-border: #e2c4be;

$white-card-active: #fcfbfa;
$white-card-border: #c5c8ca;

$disabled-card: rgba(255, 255, 255, 0.5);

$link-color: rgba(0, 50, 226, 0.8);

$progress-bar-color: #4caf50;
$progress-bar-background-color: #f5f5f5;
