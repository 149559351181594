@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.completion-notify-bar {
  background-color: $white-color;
  border-radius: 65px;
  padding: 5px 25px;
  width: fit-content;

  @include smallScreen {
    width: auto;
  }

  .notify-stack {
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    .checkmark-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;

      @include smallScreen {
        width: 20px;
        height: 20px;
      }
    }

    .notify-text {
      letter-spacing: 0px;
      font-size: 18px;
      font-family: Arial, Helvetica, sans-serif;
      margin-bottom: 8px;

      @include smallScreen {
        font-size: 14px;
      }
    }
  }
}
