@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.tutorial-container {
  height: calc(100vh - 50px);

  .base-stack {
    align-items: center;
    justify-content: space-evenly;
    height: 90%;

    @include smallScreen {
      height: 89%;
    }

    @include extraLargeScreen {
      height: 97%;
      justify-content: space-around;
    }

    .screen {
      width: 100%;
      max-width: 600px;

      @include largeScreen {
        height: 85%;
      }

      @include smallScreen {
        height: 90%;
      }

      @include extraLargeScreen {
        height: 80%;
      }
    }

    .button-stack {
      width: 90%;
      justify-content: space-between;
      margin-top: 10%;
      align-items: flex-start;
      height: 100px;

      @include smallScreen {
        justify-content: space-between;
        width: 100%;
        margin-top: 2%;
      }

      @include extraLargeScreen {
        margin-top: 0px;
      }

      .skip-btn {
        background-color: $white-color;
        color: $primary-black-color;
        letter-spacing: 1.51px;
        padding: 15px 50px;
        font-weight: 700;
      }

      .div {
        position: relative;
        .next-btn {
          padding: 15px 100px;
          text-align: space-between;
  
          @include smallScreen {
            padding: 15px 50px;
          }
  
          .icon {
            margin-left: 10px;
          }
        }
  
        .press-gif-overlay {
          position: absolute;
          top: 15px;
          left: 45%;
          width: 100px;
          height: 100px;
          pointer-events: none;
        }
      }



      .sound-gif-overlay {
        position: fixed;
        top: 12%;
        left: 82%;
        width: 80px;
        height: 80px;
        rotate: -20deg;

        @include extraLargeScreen {
          width: 50px;
          height: 50px;
          top: 17%;
          left: 55%;
        }

        @include largeScreen {
          width: 65px;
          height: 70px;
          top: 16%;
          left: 61%;
        }

        @include mediumScreen {
          width: 50px;
          height: 50px;
          top: 17%;
          left: 61%;
        }

        @include smallScreen {
          width: 40px;
          height: 40px;
          top: 17%;
          left: 66%;
        }

        @include extraSmallScreen {
          width: 35px;
          width: 35px;
          top: 15%;
        }
      }

      .sound-2-gif-overlay {
        position: fixed;
        top: 9%;
        left: 63%;
        width: 75px;
        height: 80px;
        rotate: 180deg;

        @include extraLargeScreen {
          width: 45px;
          height: 50px;
          top: 12.5%;
          left: 57.5%;
        }

        @include largeScreen {
          width: 65px;
          height: 70px;
          top: 11%;
          left: 67%;
        }

        @include mediumScreen {
          width: 40px;
          height: 45px;
          top: 11%;
          left: 67%;
        }

        @include smallScreen {
          width: 35px;
          height: 40px;
          top: 12.5%;
          left: 74%;
        }

        @include extraSmallScreen {
          width: 30px;
          height: 35px;
          top: 10.5%;
          left: 73%;
        }
      }

      .sound-3-gif-overlay {
        position: fixed;
        top: 9%;
        left: 63%;
        width: 75px;
        height: 80px;
        rotate: 180deg;

        @include extraLargeScreen {
          width: 45px;
          height: 50px;
          top: 12.5%;
          left: 54%;
        }

        @include largeScreen {
          width: 65px;
          height: 70px;
          top: 11%;
          left: 59%;
        }

        @include mediumScreen {
          width: 40px;
          height: 45px;
          top: 11%;
          left: 59%;
        }

        @include smallScreen {
          width: 35px;
          height: 40px;
          top: 12.5%;
          left: 63%;
        }

        @include extraSmallScreen {
          width: 30px;
          height: 35px;
          top: 10.5%;
          left: 62.5%;
        }
      }
    }
  }
}
