@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.settings-container {
  width: 100%;
  height: 100vh;
  margin-top: 44px;

  .loader {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    margin-top: 50px;
  }

  .general-settings-stack {
    width: 100%;
    margin-left: 44px;
    .heading {
      .back-btn {
        width: 32px;
        height: 32px;

        .back-img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .first-stack {
      background-color: $white-color;
      border-radius: 20px;
      flex-grow: 1;
      width: 422.172px;
      height: 235.575px;
      flex-shrink: 0;

      .information {
        margin-left: 20px;
        font-family: 'Lexend', sans-serif !important;
        font-size: 17px !important;
        font-weight: 500 !important;
        margin-bottom: 15px;
      }

      .content {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 70px;

        .changeButton {
          background-color: #b5f599;
          color: #22af47;
          text-transform: none;
          margin-bottom: 10px;
          margin-right: 10px;
          padding: 4px 10px;
          &:hover {
            background-color: #e8f5e9;
          }
          &:disabled {
            background-color: grey;
            color: white;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@include smallScreen() {
  .settings-container {
    .general-settings-stack {
      .heading {
        .back-btn {
          width: 18px;
          height: 18px;
  
          .back-img {
            width: 18px;
            height: 18px;
          }
        }
      }
      .first-stack {
        margin-top: 10px;
        width: 90%;
        height: fit-content;
        flex-shrink: 0;
      }
    }
  }
}
