@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.MuiInputBase-root .MuiOutlinedInput-root {
  border-radius: 50px !important;
}

.label-stack {
  align-items: center;
  text-align: center;

  .label {
    color: $label-gray-color;
  }

  .info-icon {
    margin-left: 5px;
    color: $label-gray-color;
    height: 22px;
    width: 22px;
    margin-bottom: 5px;
  }
}

.change-btn {
  text-transform: none !important;

  &:hover {
    background-color: $primary-green-color;
    color: $white-color;
    border: none;
    outline: none;
  }
}
