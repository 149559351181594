@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.profile-header-container {
  width: 100%;

  .profile-header-content {
    width: 100%;

    .greeting-text {
      margin: 10px;

      @include smallScreen {
        font-size: 16px;
        margin: 8px;
      }
    }

    .dropdown-icon {
      background-color: $light-gray-color;
      width: 24px;
      height: 24px;

      @include smallScreen {
        width: 20px;
        height: 20px;
      }
    }

    .profile-box {
      width: 65%;
      padding-block: 10px;
      padding-inline: 20px;
      border: 5px solid $box-border-light-gray-color;
      background-color: $white-color;
      border-radius: 20px;
      align-items: flex-start;
      justify-content: center;
      height: 170px;

      @include extraLargeScreen {
        width: 80%;
      }

      @include smallScreen {
        border: 3px solid $box-border-light-gray-color;
        width: 55%;
        height: 100px;
        padding-inline: 10px;
      }

      .profile-image {
        min-height: 100px;
        min-width: 100px;

        @include smallScreen {
          min-height: 50px;
          min-width: 50px;
        }
      }

      .profile-stack {
        width: 100%;
        margin-left: 20px;
        text-align: start;

        @include smallScreen {
          margin-left: 10px;
        }

        .profile-level {
          font-weight: 500;
          letter-spacing: 0px;
          margin-bottom: 10px;
        }
      }
    }

    .rewards-box {
      width: 35%;
      padding-block: 10px;
      padding-inline: 20px;
      border: 5px solid $box-border-light-gray-color;
      background-color: $white-color;
      border-radius: 20px;
      position: relative;
      height: 170px;
      justify-content: flex-end;
      cursor: pointer;

      @include smallScreen {
        border: 3px solid $box-border-light-gray-color;
        width: 45%;
        height: 100px;
        padding-inline: 10px;
      }

      .reward-sticker {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 110px;
        width: 110px;

        @include smallScreen {
          top: 5px;
          right: 5px;
          height: 60px;
          width: 60px;
        }
      }

      .rewards-stack {
        .earned-text {
          @include smallScreen {
            font-size: 10px;
          }
        }
        .rewards-text {
          font-weight: 800;
          letter-spacing: 0px;
          font-size: 16px;

          @include extraLargeScreen {
            font-size: 20px;
          }

          @include largeScreen {
            font-size: 20px;
          }

          @include smallScreen {
            font-size: 12px;
          }
        }
        .arrow {
          height: 30px;
          width: 25px;
          margin-top: 2px;
          margin-left: 10px;
          color: #59b948;

          @include smallScreen {
            height: 20px;
            width: 15px;
            margin-top: 0px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
