@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.chest-modal-container {
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: auto;
  margin-top: 10%;
  background-color: $white-color;
  border-radius: 25px;
  padding: 5% 0%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  @include largeScreen {
    margin-top: 20%;
    width: 50%;
  }

  @include smallScreen {
    margin-top: 30%;
    width: 80%;
  }

  .heading {
  }

  .chest-img {
    max-width: 180px;
    max-height: 180px;
  }
}
