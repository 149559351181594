@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.table-container {
  background-color: $white-color;
  border: 4px solid $box-border-light-gray-color-3;
  border-radius: 16px;
  // overflow-x: auto;

  .table {
    min-width: 650px;
    width: 100%;

    @include smallScreen {
      min-width: 0;
    }

    .student-profile-stack {
      align-items: center;
      width: 100%;

      .avatar {
        width: 64px;
        height: 64px;
        border: 4px solid $profile-picture-border-color;
        border-radius: 32.5px;
      }
    }
  }

  .view-students-btn {
    width: 100%;
    border-radius: 12px;
    padding: 12px;
    background-color: $primary-green-color-opacity;

    .btn-text {
      text-transform: initial;
      color: $primary-green-color;
    }

    &:hover {
      background-color: $primary-green-color;

      .btn-text {
        color: $white-color !important;
      }
    }
  }
}

.popover {
  .edit-button {
    border-radius: 0;

    &:hover {
      background-color: $primary-green-color;
      color: $white-color;
      border: none;
      outline: none;
    }
  }

  .delete-button {
    border-radius: 0;

    &:hover {
      background-color: $red-color;
      color: $white-color;
      border: none;
      outline: none;
    }
  }
}
