@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.delete-modal {
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 45%;
  margin: auto;
  margin-top: 5%;
  border-radius: 40px;
  padding: 3%;
  padding-top: 6%;
  background-image: url('../../Assets/Images/deletion-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @include largeScreen {
    margin-top: 10%;
    padding-top: 10%;
    padding-bottom: 5%;
    width: 70%;
  }

  @include smallScreen {
    margin-top: 40%;
    padding-top: 20%;
    padding-bottom: 10%;
    width: 95%;
  }

  .header-text {
    font-weight: 600;
    letter-spacing: 0;
    margin-top: 15%;
    text-align: center;
    width: 90%;
    color: $maroon-color;

    @include smallScreen {
      width: 95%;
      margin-top: 5%;
    }
  }

  .sub-text {
    font-weight: 400;
    letter-spacing: 2.28px;
    line-height: 33px;
    text-align: center;
    width: 85%;

    @include smallScreen {
      width: 95%;
      line-height: 20px;
    }
  }

  .button-stack {
    width: 100%;
    align-items: center;
    justify-content: center;

    .delete-button {
      padding: 12px !important;
      text-transform: none !important;
      border-radius: 12px !important;
    }

    .cancel-button {
      padding: 12px !important;
      text-transform: none !important;
      border-radius: 12px !important;
      background-color: $white-color !important;
      color: $primary-black-color !important;
      border: 3px solid $primary-black-color !important;
    }
  }
}
