@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.list-item-stack {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 188px;
  background-color: $white-color;
  border-radius: 24px;
  border: 5px solid $box-border-light-gray-color;
  // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);

  .heading-stack {
    justify-content: space-between;
    align-items: center;
    height: 62px;
    width: 95%;
    border-radius: 16px;
    background-color: $box-border-light-gray-color-2;

    .link {
      cursor: pointer;
      text-decoration: none;
      color: $tertiary-black-color;
      margin-left: 5%;
      width: 70%;
    }

    .time {
      background-color: $medium-green-border-color;
      border-radius: 30px;
      padding: 5px 12px;
      text-align: center;
      text-justify: center;
      margin-right: 5%;
    }
  }

  .session-result-stack {
    width: 95%;
    align-items: center;
    justify-content: space-between;

    .session-completion-time-stack {
      margin-left: 5%;
      align-items: center;

      .hour-glass-img {
        width: 18px;
        height: 18px;
      }
    }

    .score-stack {
      margin-right: 5%;
      align-items: center;

      .score-heading {
        color: $tertiary-black-color;
        opacity: 0.4;
      }

      .score {
        span {
          font-size: 56px;
          line-height: 1;
        }
      }
    }
  }
}

@include smallScreen() {
  .list-item-stack {
    height: 98px;
    border-radius: 12px;
    border: 3px solid $box-border-light-gray-color;

    .heading-stack {
      height: 32px;
      border-radius: 8px;

      .link {
        .session-number {
          margin-left: 4%;
        }
      }

      .time {
        font-size: 8px;
        border-radius: 15px;
        padding: 3px 6px;
        margin-right: 4%;
      }
    }

    .session-result-stack {
      .session-completion-time-stack {
        margin-left: 4%;

        .hour-glass-img {
          width: 9px;
          height: 9px;
        }

        .text {
          font-size: 10px;
          letter-spacing: 0.9px;
        }
      }

      .score-stack {
        margin-right: 4%;

        .score-heading {
          font-size: 10px;
        }

        .score {
          font-size: 10px;

          span {
            font-size: 28px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@include extraSmallScreen() {
  .list-item-stack {
    .session-result-stack {
      .session-completion-time-stack {
        .text {
          font-size: 8px;
          letter-spacing: 0.8px;
        }
      }

      .score-stack {
        .score-heading {
          font-size: 8px;
        }
      }
    }
  }
}
