@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.add-student-container {
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  padding-bottom: 10px !important;

  .base-stack {
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: 25%;
    margin-top: 5%;
    margin-bottom: 5%;

    .raccoon-avatar {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 20px;
      align-self: center;
    }

    @include largeScreen {
      width: 80%;
      margin-left: 10%;
    }

    @include smallScreen {
      width: 100%;
      margin-left: 0%;
    }

    .carousel-image {
      width: 140px;
      height: 140px;
      object-fit: cover;
      cursor: pointer;

      @include smallScreen {
        width: 80px;
        height: 80px;
      }
    }

    .fields-stack {
      width: 100%;
      text-align: start;

      @include smallScreen {
        width: 90%;
      }
    }

    .btn-stack {
      width: 100%;

      @include smallScreen {
        width: 90%;
      }

      .add-button {
        padding: 12px !important;
        text-transform: none !important;
        border-radius: 12px !important;
        height: 60px !important;
        font-size: 16px;
      }

      .save-button {
        border-radius: 12px;
        padding: 0px;
        border: 3px solid $black-color;
        flex-grow: 1;
        height: 60px;
        text-transform: none;
        font-size: 16px;
        margin-bottom: 20px !important;
      }
    }

    .delete-button {
      border-radius: 12px;
      padding: 0px;
      border: 3px solid $red-color;
      flex-grow: 1;
      height: 60px;
      text-transform: none;
      font-size: 16px;
      margin-bottom: 20px !important;
    }

    .resend-link {
      text-decoration: none;
      color: $black-color;
      font-size: 16px;
      letter-spacing: 1.98px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
