@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.container-stack {
  width: 100%;
  margin-bottom: 100px;
  align-items: center;
  padding-block-start: 20px;
  padding-block-end: 100px;

  .scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }

  .heading {
    width: 90%;
    align-items: center;
    justify-content: flex-start;

    .back-btn {
      width: 32px;
      height: 32px;

      .back-img {
        width: 32px;
        height: 32px;
      }
    }

    .page-name {
      font-weight: 700;
      color: $primary-black-color;
    }
  }

  .top-container {
    justify-content: flex-start;
    width: 70%;
    align-items: center;
    margin-bottom: 5%;
    position: relative;
    height: 400px;
    margin-bottom: 100px;
    background: url(../../Assets/Images/result-card.svg) no-repeat center /
      contain;

    .top-content {
      height: 100%;
      width: 70%;
      align-items: center;
      justify-content: center;
      gap: 3%;
      z-index: 1;

      .top-heading {
        margin-top: 23%;
      }

      .score {
        span {
          font-size: 85px !important;
        }
      }
    }

    .top-container-footer {
      z-index: 1;
      position: absolute;
      bottom: -50px;
      align-items: center;
      justify-content: center;
      width: 90%;
      background: url(../../Assets/Images/result-card-bottom.svg) no-repeat
        center / contain;
      height: 70px;

      .text {
        z-index: 11;
        letter-spacing: 0 !important;
      }
    }
  }
}

@include smallScreen() {
  .container-stack {
    .heading {
      .back-btn {
        .back-img {
          width: 24px;
          height: 24px;
        }
      }

      .page-name {
        font-size: 16px;
      }
    }

    .top-container {
      height: 320px;
      margin-bottom: 50px;

      .top-content {
        .top-heading {
          margin-top: 27%;
        }

        .score {
          span {
            font-size: 50px !important;
          }
        }
      }

      .top-container-footer {
        bottom: 10px;
        height: 40px;

        .text {
          font-size: 10px;
        }
      }
    }
  }
}

@include extraLargeScreen() {
  .container-stack {
    .top-container {
      .top-content {
        gap: 2%;

        .top-heading {
          margin-top: 20%;
        }
      }
    }
  }
}
