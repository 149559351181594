@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.deactivated-modal {
  width: 60%;
  margin: auto;
  padding: 10% 0% 2%;
  border-radius: 40px;
  background-image: url('../../Assets/Images/bg-with-raccoon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include extraLargeScreen {
    padding: 6% 0% 2%;
    margin-top: 10%;
    width: 40%;
  }

  @include largeScreen {
    padding-top: 10%;
    padding-bottom: 5%;
    margin-top: 10%;
    width: 65%;
  }

  @include smallScreen {
    background-image: url('../../Assets/Images/bg-with-raccoon-for-mobile.svg');
    // background-position: center;
    margin-top: 40%;
    padding: 15% 0% 5%;
    width: 90%;
  }

  .password-container {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .header-text {
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    width: 80%;
    color: #c73535;

    @include smallScreen {
      width: 95%;
    }
  }

  .sub-text {
    font-weight: 400;
    letter-spacing: 2.28px;
    line-height: 33px;
    text-align: center;
    width: 75%;

    @include smallScreen {
      width: 70%;
      line-height: 20px;
    }
  }

  .subscription-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
    background-color: #54b948;
    font-size: 16px;
    font-weight: 700;
  }
}
