@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.splash-container {
  height: calc(100vh - 50px);
  padding: 0px !important;
  background-color: $box-border-light-gray-color-2;

  .base-stack {
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .background-img {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 70%;
      object-fit: cover;
    }
  }
}

@include smallScreen() {
  .splash-container {
    .base-stack {
      .background-img {
        height: 60%;
      }
    }
  }
}

@include extraLargeScreen() {
  .splash-container {
    .base-stack {
      justify-content: center;
      align-items: flex-end;

      .background-img {
        height: 100%;
      }
    }
  }
}
