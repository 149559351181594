@use '../../Styles' as *;

.navbar-box {
  background-color: white;
  border: 3px solid $box-border-light-gray-color;
  border-radius: 20px;
  margin-left: 20px;
  padding: 20px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;

  .navbar-divider {
    border-bottom-width: 3px;
  }

    .list-button {
        padding-left: 20px;
        padding-right: 50px;
        border-radius: 20px;

        .list-highlight {
            color: $primary-green-color;
        }
    } 
}
