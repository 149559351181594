@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.create-account-box {
  align-items: center;
  justify-content: center;

  @include smallScreen {
    height: calc(100vh - 50px);
    justify-content: start;
  }

  .small-screen-heading {
    width: 100%;
    margin-top: 5%;
    align-items: center;
    justify-content: flex-start;

    .back-btn {
      width: 32px;
      height: 32px;

      .back-img {
        width: 24px;
        height: 24px;
      }
    }

    .page-name {
      font-weight: 700;
      color: $primary-black-color;
    }
  }

  .header-text {
    margin-top: 10%;

    @include smallScreen {
      align-self: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.25px;
    }

    @include extraLargeScreen {
      margin-top: 10%;
    }
  }

  .box-stack {
    align-items: center;
    margin-bottom: 5%;

    .content-box {
      background-color: $primary-green-color-opacity;
      border: 3px solid $primary-green-color;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      min-height: 121px;
      padding: 30px 20px;
      width: 95%;
      max-width: 500px;

      @include smallScreen {
        width: 100%;
      }

      &:hover {
        background-color: $medium-green-border-color;
        cursor: pointer;
      }

      .content-stack {
        justify-content: space-evenly;

        .avatar-image {
          width: 37px;
          height: 37px;
          justify-self: start;

          @include extraLargeScreen {
            width: 65px;
            height: 65px;
          }

          @include largeScreen {
            width: 55px;
            height: 55px;
          }

          @include mediumScreen {
            width: 50px;
            height: 50px;
          }
        }

        .text-stack {
          justify-content: space-around;
          align-items: flex-start;
          margin-left: 10px;

          .heading {
            font-size: 16px;
            letter-spacing: 1.68px;
          }

          .sub-text {
            letter-spacing: 1.68px;
            color: $gray-color;
            text-wrap: wrap;
            max-width: 280px;

            @include mediumScreen {
              max-width: 400px;
            }

            @include smallScreen {
              max-width: 230px;
              letter-spacing: 1.47px;
            }
          }
        }

        .arrow-icon {
          width: 15px;
          height: 15px;
          justify-self: flex-end;
          align-self: center;
        }
      }
    }
  }
}
