@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.student-card {
  border-radius: 16px;
  border: 4px solid $light-green-border-color;
  min-width: 230px !important;
  height: 120px;
  background-color: $white-color;
  align-items: center;
  justify-content: center;

  .card-profile-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .content {
    width: 60%;
    overflow: hidden;

    .username {
      text-overflow: clip;
    }

    .student-level {
      background-color: rgba(22, 39, 49, 0.1);
      border-radius: 30px;
      padding: 5px 12px;
      width: fit-content;
    }
  }
}

@include smallScreen {
  .student-card {
    min-width: 151px !important;
    height: 80px;

    .card-profile-img {
      width: 40px;
      height: 40px;
    }

    .content {
      width: 50%;
      .username {
        font-size: 12px;
        line-height: 15px;
      }

      .student-level {
        font-size: 10.68px;
      }
    }
  }
}
