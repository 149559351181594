@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.spelling-selection-box {
  align-items: center;
  justify-content: center;

  @include smallScreen {
    height: calc(100vh - 50px);
    justify-content: start;
  }

  .small-screen-heading {
    width: 100%;
    margin-top: 5%;
    align-items: center;
    justify-content: flex-start;

    .back-btn {
      width: 32px;
      height: 32px;

      .back-img {
        width: 24px;
        height: 24px;
      }
    }

    .page-name {
      font-weight: 700;
      color: $primary-black-color;
    }
  }

  .header-text {
    margin-top: 10%;
    font-size: 48px;

    @include smallScreen {
      align-self: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.25px;
    }

    @include extraLargeScreen {
      font-size: 32px !important;
    }
  }

  .sub-text {
    text-wrap: wrap;
    text-align: center;
    width: 70%;

    @include smallScreen {
      text-align: start;
      width: 90%;
    }

    @include extraLargeScreen {
      width: 90%;
    }
  }

  .box-stack {
    align-items: center;

    .content-box {
      background-color: $primary-green-color-opacity;
      border: 3px solid $primary-green-color;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      min-height: 121px;
      padding: 30px 20px;
      width: 95%;
      max-width: 500px;

      &:hover {
        background-color: $medium-green-border-color;
        cursor: pointer;
      }

      .content-stack {
        justify-content: space-between;

        .avatar-image {
          width: 37px;
          height: 37px;
          justify-self: start;
          align-self: center;

          @include extraLargeScreen {
            width: 70px;
            height: 70px;
          }

          @include largeScreen {
            width: 60px;
            height: 60px;
          }

          @include mediumScreen {
            width: 50px;
            height: 50px;
          }
        }

        .heading {
          font-size: 16px;
          letter-spacing: 1.69px;
          font-weight: 700;
          align-self: center;
          justify-self: start;
          margin-left: 20px;
        }

        .arrow-icon {
          width: 15px;
          height: 15px;
          justify-self: flex-end;
          align-self: center;
        }
      }
    }
  }
}
