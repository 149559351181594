@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.base-stack {
  align-items: center;
  background-color: $white-color;
  width: 92%;
  border-radius: 21px 21px 0px 0px;
  justify-content: space-evenly;
  z-index: 111;
  height: 44%;
  padding: 0 0 20px 0px;

  .text-stack {
    margin-top: 50px;
    max-width: 450px;
    align-items: center;
  }

  .button-stack {
    width: 90%;
    margin-bottom: 30px;

    .button {
      height: 66px;
      font-size: 20px;
      letter-spacing: 1.68px;
      text-transform: none;
    }
  }
}

@include mediumScreen() {
  .base-stack {
    height: 45%;

    .text-stack {
      margin-top: 40px;
    }
  }
}

@include smallScreen() {
  .base-stack {
    height: 55%;

    .button-stack {
      .button {
        height: 56px;
        font-size: 16px;
      }
    }

    .text-stack {
      margin-top: 30px;
    }
  }
}

@include extraSmallScreen() {
  .base-stack {
    .text-stack {
      margin-top: 20px;
    }
  }
}

@include extraLargeScreen() {
  .base-stack {
    height: 60%;
    width: 33%;
    margin-right: 4%;
    border-radius: 21px;

    .button-stack {
      .button {
        height: 56px;
        font-size: 16px;
      }
    }
  }
}
