@import '../../Styles/mediaQueries';

.text-heading {
  font-size: 40px !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-align: center;

  @include mediumScreen {
    font-size: 24px !important;
  }
}

.countdown {
  margin-top: 2em;
  font-size: 48px !important;
  font-weight: 700 !important;

  @include mediumScreen {
    font-size: 24px !important;
  }
}

.hidden {
  visibility: hidden;
  font-size: 48px !important;

  @include mediumScreen {
    font-size: 24px !important;
  }
}
