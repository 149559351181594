.top-container {
  height: 100%;

  .player-container {
    width: 210px;
    height: 210px;
  }

  .player-button {
    margin-bottom: 1em;
    padding: 0.5em 1em;
    background-color: #54B948;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    color: white;
    letter-spacing: 2px;
    text-transform: none;
  }
}
