@mixin extraSmallScreen {
  @media screen and (max-width: 380px) {
    @content;
  }
}

@mixin smallScreen {
  @media screen and (max-width: 550px) {
    @content;
  }
}

@mixin mediumScreen {
  @media screen and (max-width: 769px) {
    @content;
  }
}

@mixin largeScreen {
  @media screen and (max-width: 1020px) {
    @content;
  }
}

@mixin extraLargeScreen {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin extraLargeScreenByHeight {
  @media screen and (min-height: 900px) {
    @content;
  }
}
