@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.progress-badge-box-container {
  background-color: $blue-card;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  border: 5px solid $blue-card-border;

  &.yellow {
    background-color: $mustard-card;
    border: 5px solid $yellow-card-border;
  }

  &.green {
    background-color: $light-green-border-color;
    border: 5px solid $dark-green-border-color;
  }

  .progress-badge-box-content {
    width: 70%;
    min-height: 130px;
    justify-content: center;
    padding-left: 10px;

    @include smallScreen{
      min-height: 120px;
      width: 80%;
    }

    .heading {
      width: 100%;
    }

    .sub-text {
      width: 100%;
      color: $label-gray-color;
      margin-bottom: 15px;
    }
  }

  .badge-box {
    width: 30%;
    align-items: center;
    justify-content: center;

    .badge {
      width: 90px;
      height: 90px;

      @include smallScreen{
        width: 75px;
        height: 75px;
      }
    }
  }
}
