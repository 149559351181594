@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.signup-box {
  align-items: center;
  justify-content: center;

  input, select, textarea {
    font-size: 16px;
    @include smallScreen {
      font-size: 16px !important;
    }
  }
  @include smallScreen {
    height: calc(100vh - 50px);
    justify-content: start;
  }

  .small-screen-heading {
    width: 100%;
    margin-top: 5%;
    align-items: center;
    justify-content: flex-start;

    .back-btn {
      width: 32px;
      height: 32px;

      .back-img {
        width: 24px;
        height: 24px;
      }
    }

    .page-name {
      font-weight: 700;
      color: $primary-black-color;
    }
  }

  .header-text {
    font-size: 48px;
    margin-top: 10%;

    @include smallScreen {
      align-self: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.25px;
    }

    @include extraLargeScreen {
      font-size: 32px !important;
      margin: auto;
    }
  }

  .fields-stack {
    @include extraSmallScreen {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 600px;
      height: 450px;
    }

    @include extraLargeScreen {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 700px;
      height: 460px;

      @include extraLargeScreenByHeight {
        max-height: 800px;
        height: 550px;
      }
    }

    .profile-image-box {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .profile-image {
        height: 90px;
        width: 90px;
        border: 6px solid $profile-picture-border-color;
        border-radius: 50% !important;
        padding: 8px;
        box-shadow: inset 0px 0px 0px 8px $white-color;
      }

      .edit-btn {
        position: absolute;
        bottom: -5px;
        right: 44%;
        background-color: $primary-green-color;
        border-radius: 50%;
        padding: 0;
        width: 35px;
        height: 35px;
        border: 4px solid $dark-green-border-color;
        z-index: 1;

        @include extraLargeScreen {
          right: 37%;
        }

        @include largeScreen {
          right: 42%;
        }

        @include mediumScreen {
          right: 40%;
        }

        @include smallScreen {
          right: 35%;
        }

        .edit-btn-image {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .next-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}
