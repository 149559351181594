@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.verify-email-box {
  align-items: center;
  justify-content: center;
  margin-bottom: 30%;

  @include smallScreen {
    height: calc(100vh - 50px);
    justify-content: start;
  }

  .header-text {
    margin-top: 20%;
    font-size: 48px;

    @include smallScreen {
      align-self: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.25px;
    }

    @include extraLargeScreen {
      font-size: 32px !important;
    }
  }

  .sub-text {
    color: $primary-black-color;
    text-align: center;
    text-wrap: wrap;
    letter-spacing: 1.98px;
    line-height: 35.6px !important;
    width: 90%;

    @include smallScreen {
      text-align: start;
      line-height: 27px !important;
    }
  }

  .resend-link {
    color: $link-color;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 35.558px;
    letter-spacing: 1.975px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .status-text {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    line-height: 35.558px;
    letter-spacing: 1.975px;
  }
}
