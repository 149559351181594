@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.title-word {
  align-items: center;
  gap: 75px;
  position: relative;

  @include mediumScreen {
    gap: 50px;
  }

  @include extraSmallScreen {
    gap: 30px;
  }
  
  .heading {
    font-size: 28px;

    @include largeScreen {
      font-size: 50px;
    }

    @include mediumScreen {
      font-size: 20px;
    }

    @include extraSmallScreen {
      font-size: 16px;
    }
  }

  .visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    pointer-events: none;
  }

  .separated {
    justify-content: center;
    margin: 0 5%;
    width: 90%;

    .letter-cursor {
      align-items: end;

      .shake {
        top: -50px;

        @include mediumScreen {
          top: -25px;
        }

        @include extraSmallScreen {
          top: -15px;
        }
      }

      .sound-box-letters {
        padding-bottom: 12px !important;
        font-size: 40px !important;
        font-weight: 200 !important;
        color: black;
        letter-spacing: 1.6px;
        line-height: 200px !important;
        text-align: center;
        text-justify: center;
        border: 8px solid;
        border-radius: 24px;
        margin: 0 12px !important;
        padding: 0;

        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        position: relative;

        //TODO: Fix the font-family and min-max width/height.
        //TODO: The font family must be applied through typography.
        //TODO: remove !important tags
        min-width: 160px;
        max-height: 190px;
        font-family: 'M PLUS Rounded 1c' !important;
        font-weight: 400 !important;
        color: #162731 !important;

        &:last-child {
          margin-right: 0;
        }

        @include largeScreen() {
          border: 7px solid;
          padding-bottom: 4px !important;
        }

        @include mediumScreen() {
          font-size: 20px !important;
          border: 4px solid;
          min-width: 70px !important;
          max-height: 100px;
          margin: 0 4px !important;
        }

        @include extraSmallScreen() {
          font-size: 16px !important;
          border: 3px solid;
          padding: 0;
        }
      }

      .gif-overlay {
        position: absolute;
        top:  85%;
        width: 90px;
        height: 90px;

        @include largeScreen {
          width: 125px;
          height: 125px;
        }

        @include mediumScreen {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

.yellow {
  background-color: $yellow-card-active !important;
  border-color: $yellow-card-border !important;
}

.pink {
  background-color: $pink-card-active !important;
  border-color: $pink-card-border !important;
}

.green {
  background-color: $green-card-active !important;
  border-color: $green-card-border !important;
}

.white {
  background-color: $white-card-active !important;
  border-color: $white-card-border !important;
}

.disabled {
  position: relative;
  transform: scale(0.8);
  transition-duration: 0.5s;
}

.disabled::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $disabled-card;
  border: 8px solid $disabled-card;
  border-radius: 25px;
}
