@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.MuiInputBase-root .MuiOutlinedInput-root {
  border-radius: 50px !important;
}

.label {
  color: $label-gray-color;
}
