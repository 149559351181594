@use '../../Styles' as *;

.container-stack {
  background-color: $white-color;
  width: 100%;
  justify-content: space-between;
  height: 170px;
  border-radius: 30px;
  border: 4px solid $black-border-color;
  padding: 0px 20px 0px 20px;

  .credentials-stack {
    width: 100%;
    align-items: center;

    .profile-photo-box {
      position: relative;
      display: inline-block;

      .profile-photo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: solid 6px $profile-picture-border-color;
        padding: 6px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 8px $white-color;
      }

      .edit-profile-photo {
        position: absolute;
        width: 32px;
        height: 32px;
        bottom: 5px;
        right: 5px;
        background-color: $primary-green-color;
        border-radius: 50%;
        padding: 0;
        border: 4px solid $dark-green-border-color;
        z-index: 1;

        .edit-button {
          height: 16px;
          width: 16px;
        }
      }
    }

    .username-head {
      color: $secondary-black-color;
    }

    .username {
      color: $primary-black-color;
    }
  }

  .buttons-stack {
    align-items: center;

    .signout {
      border-radius: 12px;
      padding: 12px;
      border: 3px solid $black-color !important;
      flex-grow: 1;
      width: 15rem;
      text-transform: none;
    }

    .options {
      background-color: $white-color;
      border-radius: 12px;
      padding: 12px;
      border: 3px solid $black-color;
    }
  }
}

.popover {
  .btn-stack {
    width: 100%;
    align-items: flex-start;

    .icon-btn {
      padding: 15px 25px;
      .btn-text {
        flex-wrap: nowrap;
      }
    }
  }
}
