.title {
  margin-top: 1em;
  font-size: 24px !important;
  font-weight: 500 !important; 
  letter-spacing: 2px;
}

.words {
  color: #54B948;
}

.practice_one {
  color: #C9B544;
}

.challenge-now {
  color: #DB6573;
}

.practice-again {
  color: #C9B544;
}

.practice_two {
  color: #C9B544;
}

.practice_three {
  color: #C9B544;
}

.loader {
  align-self: center;
}