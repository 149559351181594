@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.list-container-stack {
  width: 80%;

  .divider {
    width: 90%;
    align-items: center;

    .divider-text {
      color: $dark-gray-color;
    }

    .divider-line {
      flex-grow: 1;
      height: 5px;
    }
  }
}

@include smallScreen() {
  .list-container-stack {
    .divider {
      .divider-text {
        font-size: 10px;
        letter-spacing: 0.9px;
      }
    }
  }
}
