@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.change-password-modal {
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 5%;
  background-color: $white-color;
  border-radius: 25px;
  padding: 20px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  .cross-btn {
    align-self: flex-end;
    margin-right: 5px !important;
    width: 24px;
    height: 24px;
  }

  @include extraLargeScreen {
    width: 30%;
  }

  @include smallScreen {
    margin-top: 35%;
    width: 80%;
  }

  .header-text {
    font-size: 30px;
    font-weight: 700;
    margin-top: 5%;
    text-align: center;
  }

  .fields-stack {
    width: 90%;
  }

  .next-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}
