@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.star-img {
  width: 36px;
  height: 36px;

  @include smallScreen {
    width: 20px;
    height: 20px;
  }
}

.star-spike-img {
  width: 46px;
  height: 46px;

  @include smallScreen {
    width: 30px;
    height: 30px;
  }
}

.progress-bar {
  color: $progress-bar-color;
  background-color: $progress-bar-background-color !important;
}
