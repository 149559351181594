@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.profile-container {
  align-items: center;
  padding-bottom: 50px;

  .profile-content {
    margin-top: 2%;
    width: 90%;
    align-items: center;
    justify-content: center;

    .heading {
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      .back-btn {
        width: 32px;
        height: 32px;

        .back-img {
          width: 32px;
          height: 32px;
        }
      }

      .page-name {
        font-weight: 700;
        color: $primary-black-color;
      }
    }

    .profile-image-box {
      position: relative;

      .profile-image {
        border: 6px solid $profile-picture-border-color;
        padding: 8px;
        border-radius: 50%;
        box-shadow: inset 0px 0px 0px 8px $white-color;
        width: 150px;
        height: 150px;
      }
    }

    .fields-stack {
      width: 100%;

      .form-field-box {
        width: 48%;

        input, select, textarea {
          font-size: 16px;
          @include smallScreen {
            font-size: 16px !important;
          }
        }
        
      }
    }

    .student-profiles-stack {
      width: 100%;

      .heading-stack {
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .section-btn {
          color: $primary-green-color;

          .icon {
            color: $primary-green-color;
            width: 14px;
            height: 14px;
          }

          .text {
            margin-left: 2px;
          }
        }
      }

      .student-profiles-horizontal {
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
      }

      .view-students-btn {
        border-radius: 12px;
        padding: 12px;
        background-color: rgba(84, 185, 72, 0.12);
        display: none;

        .btn-text {
          text-transform: initial;
          color: $primary-green-color;
        }
      }
    }

    .buttons-stack {
      width: 100%;
      padding-bottom: 30px;

      .logout-btn {
        border-radius: 12px;
        padding: 12px;
        border: 3px solid $black-color;
        flex-grow: 1;
        height: 66px;
        text-transform: none;
      }

      .modal-btn {
        background-color: $white-color;
        border-radius: 12px;
        padding: 12px;
        border: 3px solid $black-color;
        height: 66px;
      }
    }
  }
}

@include largeScreen() {
  .profile-container {
    .profile-content {
      .fields-stack {
        .form-field-box {
          width: 100%;
        }
      }

      .student-profiles-stack {
        .view-students-btn {
          display: block;
        }
      }
    }
  }
}

@include mediumScreen() {
  .profile-container {
    margin-top: 3%;
    .profile-content {
      .buttons-stack {
        .logout-btn {
          height: 50px;
        }

        .modal-btn {
          height: 50px;
        }
      }
    }
  }
}

@include smallScreen() {
  .profile-container {
    .profile-content {
      .heading {
        .back-btn {
          width: 24px;
          height: 24px;

          .back-img {
            width: 24px;
            height: 24px;
          }
        }

        .page-name {
          font-size: 16px;
        }
      }
    }
  }
}
