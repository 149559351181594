@import '../../Styles/mediaQueries';
.StripeElement {
    border: 1px solid #e6e6e6; // Example border color, you can change it
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
  
    &:focus {
      border-color: #666EE8; // Example focus color, you can change it
    }
  
    &.StripeElement--invalid {
      border-color: #FFC7EE; // Example invalid color, you can change it
    }
  
    &.StripeElement--webkit-autofill {
      background-color: #fce883 !important;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .title {
    margin-top: 50px !important;
    font-family: 'Lexend', sans-serif !important;
    font-size: 48px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    letter-spacing: 0 !important;
    text-align: center !important;
  }

  .errorMessage {
    margin: 10px 0;
    color: red;
  }

  .content {
    background-color: white;
    width: 100%;
    max-width: 480px;
    padding: 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-top: 20px;
  }

  .paymentDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .submitButton {
    background-color: #22af47;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  
    &:hover {
      background-color: #1f993e;
    }
  
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
  
  @include smallScreen() {
    .title {
      color: var(--primary-text, #162731) !important;
      font-feature-settings: 'clig' off, 'liga' off !important;
      font-family: Lexend !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 123.5% !important; /* 19.76px */
      letter-spacing: 0.25px !important;
    }

    .content {
      background-color: white;
      width: 90%;
      max-width: 480px;
      padding: 20px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.1);
      border-radius: 8px;
      margin: 20px;
    }
  }