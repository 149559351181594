@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.password-modal {
  width: 55%;
  margin: auto;
  border-radius: 40px;
  padding: 8% 0% 2%;
  background-image: url('../../Assets/Images/bg-with-raccoon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 5%;

  input, select, textarea {
    font-size: 16px;
    @include smallScreen {
      font-size: 16px !important;
    }
  }

  @include extraLargeScreen {
    padding: 6% 0% 1%;
    margin-top: 10%;
    width: 42%;
  }

  @include largeScreen {
    background-image: url('../../Assets/Images/bg-with-raccoon-for-mobile.svg');
    padding-top: 8% 0% 5%;
    margin-top: 10%;
    width: 65%;

    @include extraLargeScreenByHeight {
      margin-top: 20%;
      padding: 8% 0% 5%;
      width: 60%;
    }
  }

  @include smallScreen {
    background-image: url('../../Assets/Images/bg-with-raccoon-for-mobile.svg');
    background-position: center;
    margin-top: 40%;
    padding: 10% 0% 3%;
    width: 95%;
  }

  .password-container {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;

    .header-text {
      font-weight: 700;
      letter-spacing: 0;
      text-align: center;
      width: 80%;

      @include smallScreen {
        width: 95%;
      }
    }

    .sub-text {
      font-weight: 400;
      letter-spacing: 2.28px;
      line-height: 33px;
      text-align: center;
      width: 55%;

      @include smallScreen {
        width: 70%;
        line-height: 20px;
      }
    }

    .fields-stack {
      width: 90%;
    }

    .enter-button {
      padding: 12px !important;
      text-transform: none !important;
      border-radius: 12px !important;
      background-color: #54b948;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
