@use '../../Styles' as *;

.modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: $white-color;
  justify-content: center;

  .modal-content {
    align-items: flex-start;
    width: 100%;

    .icon-btn {
      margin-left: 2%;
    }
  }
}
