@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.delete-modal {
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 45%;
  margin: auto;
  margin-top: 2%;
  border-radius: 50px;
  padding: 3%;
  padding-top: 6%;
  background-image: url('../../Assets/Images/deletion-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @include largeScreen {
    margin-top: 10%;
    padding-top: 10%;
    padding-bottom: 5%;
    width: 70%;
  }

  @include smallScreen {
    border-radius: 40px;
    background-position: center;
    // margin-top: 10%;
    // padding-top: 15%;
    width: 95%;
  }

  .header-text {
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 10%;
    text-align: center;
    width: 90%;

    @include smallScreen {
      // margin-top: 25%;
      width: 95%;
    }
  }

  .sub-text {
    font-weight: 400;
    letter-spacing: 2.28px;
    line-height: 33px;
    text-align: center;
    width: 85%;

    @include smallScreen {
      width: 95%;
      line-height: 20px;
      letter-spacing: 1.16px;
    }
  }

  .fields-stack {
    width: 95%;
  }

  .delete-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }

  .cancel-button {
    padding: 12px !important;
    text-transform: none !important;
    border-radius: 12px !important;
    background-color: $white-color !important;
    color: $primary-black-color !important;
    border: 3px solid $primary-black-color !important;
  }
}
