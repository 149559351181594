@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.list-item-stack {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 214px;
  background-color: $white-color;
  border-radius: 24px;
  border: 5px solid $box-border-light-gray-color;
  // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);

  .heading-stack {
    justify-content: start;
    align-items: center;
    height: 60px;
    width: 95%;
    border-radius: 16px;
    background-color: $box-border-light-gray-color-2;
    padding-left: 3%;

    .icon-img {
      height: 26px;
      width: 26px;

      @include smallScreen {
        height: 20px;
        width: 20px;
      }
    }

    .word {
      margin-left: 15px;
    }
  }

  .sounds-stack {
    width: 95%;
    align-items: center;
    justify-content: space-between;

    .sound {
      align-items: center;
      justify-content: center;
      height: 81px;
      width: 48%;
      border-radius: 12px;
      border: 3px solid $box-border-light-gray-color;
      background-color: $white-color-less-opacity;
      cursor: pointer;

      .avatar {
        width: 50px;
        height: 50px;
      }

      .username {
        margin-left: 15px;
        font-weight: 500;
        color: $tertiary-black-color;
      }
    }
  }
}

@include smallScreen() {
  .list-item-stack {
    height: 110px;
    border-radius: 12.5px;
    border: 3px solid $box-border-light-gray-color;

    .heading-stack {
      height: 32px;
      border-radius: 8.33px;

      .wrong-img {
        height: 13.5px;
        width: 13.5px;
      }

      .word {
        margin-left: 10px;
      }
    }

    .sounds-stack {
      .sound {
        height: 41px;
        border-radius: 6.25px;
        border: 1.5px solid $box-border-light-gray-color;

        .avatar {
          width: 25px;
          height: 25px;
        }

        .username {
          margin-left: 10px;
          font-size: 10px;
          letter-spacing: 0.9px;
        }
      }
    }
  }
}
