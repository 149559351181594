@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
}

.badge-modal-container {
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: auto;
  margin-top: 10%;
  background-color: $white-color;
  border-radius: 25px;
  padding: 3% 0%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  @include largeScreen {
    margin-top: 20%;
    width: 60%;
  }

  @include smallScreen {
    margin-top: 30%;
    width: 80%;
  }

  .heading {
    font-size: 24px;
  }

  .badge-img {
    width: 150px;
    height: 150px;
    animation: zoomInOut 2s infinite;
  }

  .badge-details-stack {
    width: 100%;
    align-items: center;
    justify-content: center;

    .badge-name {
    }

    .sub-text {
      color: $label-gray-color;
    }
  }

  .modal-button {
    width: 65%;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
}
