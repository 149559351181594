@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.student-profiles-container {
  align-items: center;
  width: 100%;
  padding-block-end: 30px;

  .student-profiles-stack {
    margin-top: 5%;
    width: 100%;
    align-items: center;
    justify-content: center;

    .header {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .heading {
        width: 100%;
        align-items: center;
        justify-content: flex-start;

        .back-btn {
          width: 32px;
          height: 32px;

          .back-img {
            width: 32px;
            height: 32px;
          }
        }

        .page-name {
          font-weight: 700;
          color: $primary-black-color;
        }
      }

      .section-btn {
        color: $primary-green-color;

        .icon {
          color: $primary-green-color;
          width: 14px;
          height: 14px;
        }

        .text {
          margin-left: 2px;
        }
      }
    }
  }
}
