.top-container {
     width: 100%;
     height: 100%;
     max-height: 500px;
     position: relative;
}

.animation {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
}