.navbar-paper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .navbar-bottom {
        justify-content: space-around;
    }
}
