@use '../../Styles' as *;
@import '../../Styles/mediaQueries';

.avatar-carousel-stack {
  width: 85%;
  height: 200px;
  align-items: center;
  justify-content: center;

  @include smallScreen {
    height: 130px;
  }

  .right-arrow-icon {
    width: 15px;
    height: 15px;
    justify-self: flex-end;
    align-self: center;

    &:hover {
      cursor: pointer;
    }
  }

  .left-arrow-icon {
    width: 15px;
    height: 15px;
    justify-self: flex-start;
    align-self: center;
    rotate: 180deg;

    &:hover {
      cursor: pointer;
    }
  }

  .avatar-stack {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 0;

    .image-stack {
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      // border: 0.19rem solid $primary-black-color;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
